import { Menu } from "@headlessui/react";
import {
  ChevronDownIcon,
  HomeIcon,
  NewspaperIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext } from "react";

import useAuthUser from "../hooks/useAuthUser";
import { ConfigContext } from "../pages/_app";
import getExtentFromString, {
  LS_MAP_EXTENT_KEY,
} from "../lib/getExtentFromString";
import useSetConfig from "../hooks/useConfigs";
import updateConfig from "../lib/updateConfig";

export function Logo({ className }) {
  return (
    <svg viewBox="0 0 200 50" className={className}>
      <path
        className="fill-current text-blue"
        d="M95.21 22.7a2.98 2.98 0 012.97 2.97 2.98 2.98 0 01-2.97 2.96 2.98 2.98 0 01-2.96-2.96 2.98 2.98 0 012.96-2.97z"
      />
      <path
        className="fill-current text-green"
        d="M100.27 34.44c9.28.28 16.89-9.61 14.22-18.5-2.09-8.06-11.63-13.26-19.52-9.95C87.1 8.86 83 19.06 87.17 26.45a14.84 14.84 0 0013.1 7.99zm-.55 4.94c-11.22.05-20.72-11.05-19-22.13 1.14-10.76 12.32-19.07 23-16.9 10.45 1.73 18.25 12.86 15.82 23.31-1.76 9.05-10.52 16.19-19.82 15.72z"
      />
      <path
        className="fill-current text-blue"
        d="M28 7.5H14.08c-6 0-10.87 4.13-10.87 9.22v11.3c0 5.08 4.88 9.21 10.87 9.21h20.54v3.55c0 2.75-2.98 4.99-6.64 4.99H5.72v.02l-.19-.02c-1.15 0-2.1.95-2.1 2.11s.95 2.1 2.1 2.1l.19-.01V50h22.27c6 0 10.87-4.14 10.87-9.22V16.72c0-5.1-4.88-9.23-10.87-9.23zM34.62 33H14.09c-3.66 0-6.64-2.23-6.64-4.99v-11.3c0-2.75 2.98-4.99 6.64-4.99h13.9c3.66 0 6.63 2.24 6.63 5V33z"
      />
      <path
        className="fill-current text-blue"
        d="M77.73 24.5v-7.78c0-5.09-4.87-9.23-10.87-9.23h-13.9c-6 0-10.87 4.14-10.87 9.23v11.3c0 5.08 4.87 9.21 10.87 9.21h22.68c1.16 0 2.12-.95 2.12-2.11S76.8 33 75.64 33H52.96c-3.66 0-6.64-2.24-6.64-4.99v-3.53l31.4.03zM52.96 11.73h13.9c3.66 0 6.64 2.24 6.64 5v3.54H46.32v-3.54c0-2.76 2.98-5 6.64-5z"
      />
      <path
        className="fill-current text-blue"
        d="M147.44 7.5h-13.9c-6 0-10.87 4.13-10.87 9.22v30.8c0 1.17.96 2.13 2.12 2.13 1.16 0 2.11-.96 2.11-2.12v-10.3h20.54c6 0 10.87-4.13 10.87-9.22v-11.3c0-5.08-4.88-9.22-10.87-9.22zm-20.54 9.22c0-2.76 2.98-5 6.64-5h13.9c3.66 0 6.64 2.24 6.64 5v11.3c0 2.75-2.98 4.98-6.64 4.98H126.9V16.72z"
      />
      <path
        className="fill-current text-blue"
        d="M188.7 20.17l-.15-.02h-19.04a4.24 4.24 0 01-4.22-4.22c0-2.3 1.87-4.19 4.16-4.22h25.14c1.16 0 2.11-.96 2.11-2.12a2.13 2.13 0 00-2.18-2.11H169.48a8.5 8.5 0 00-8.45 8.45 8.5 8.5 0 008.42 8.45H188.39c2.3.09 4.14 2 4.14 4.3 0 2.35-1.94 4.3-4.3 4.3h-.05l-25-.01h-.03c-1.16 0-2.11.95-2.11 2.11s.95 2.12 2.11 2.12H187.84l.08-.01h.11l.22.01h.01a8.56 8.56 0 008.52-8.52 8.56 8.56 0 00-8.08-8.51z"
      />
    </svg>
  );
}

const navigation = [
  { name: "Dashboard", icon: HomeIcon, href: "/", isActive: new RegExp("^/$") },
  {
    name: "Meldungen",
    icon: NewspaperIcon,
    href: "/notification",
    isActive: new RegExp("^/notification"),
  },
];

type Props = {
  children: React.ReactNode;
  title: string;
};

type ItemLink = {
  href: string;
  children: React.ReactNode;
};

function ItemLink(props: ItemLink) {
  const { asPath } = useRouter();
  const { href, children, ...rest } = props;
  return (
    <li>
      <Link
        href={href}
        aria-current={asPath === href ? "page" : undefined}
        className={`group flex gap-x-3 px-6 py-3 transition hover:bg-blue-50 focus-visible:bg-blue-50 focus-visible:outline-none focus-visible:ring focus-visible:ring-inset focus-visible:ring-blue-400 ${
          asPath === href ? "bg-blue-50" : ""
        }`}
        {...rest}
      >
        {children}
      </Link>
    </li>
  );
}

type ItemProps = {
  href: string;
  icon: string;
  title: string;
  subtitle?: string;
};

function Item({ href, icon, title, subtitle }: ItemProps) {
  return (
    <Menu.Item>
      <ItemLink href={href}>
        <svg className="inline-block h-6 w-6 shrink-0">
          <use xlinkHref={`/img/icon-sprite.svg#${icon}`} />
        </svg>
        <span className="flex flex-col gap-y-0.5">
          <span className="font-bold text-blue-800">{title}</span>
          {subtitle && (
            <span className="text-sm text-gray-600">{subtitle}</span>
          )}
        </span>
      </ItemLink>
    </Menu.Item>
  );
}

type HeaderMenuProps = {
  children: React.ReactElement<ItemProps>[];
  title: string;
  variant?: "default" | "blue";
};

const menuButtonClassNames = {
  default:
    "flex items-center rounded-full px-4 py-2 text-sm font-bold uppercase transition text-blue hover:text-green focus-visible:outline-none focus-visible:ring focus-visible:ring-blue-400 md:px-6",
  blue: "flex items-center px-4 py-2 text-sm font-bold uppercase transition rounded-full md:px-6 text-white bg-blue-700 hover:bg-blue-800 focus-visible:ring focus-visible:ring-blue-400 focus-visible:outline-none",
};

const openedMenuButtonClassNames = {
  default: "text-green",
  blue: "bg-blue-800",
};

function HeaderMenu({ children, title, variant = "default" }: HeaderMenuProps) {
  return (
    <Menu as="li">
      <Menu.Button className={menuButtonClassNames[variant]}>
        {({ open }) => (
          <>
            <span
              className={`whitespace-nowrap ${
                open ? openedMenuButtonClassNames[variant] : ""
              }`}
            >
              {title}
            </span>
            <svg
              className={`inline-block h-5 w-5 shrink-0 transition-transform ${
                open ? `rotate-180 ${openedMenuButtonClassNames[variant]}` : ""
              }`}
            >
              <use xlinkHref="/img/icon-sprite.svg#chevron-down" />
            </svg>
          </>
        )}
      </Menu.Button>
      <Menu.Items
        as="ul"
        className="absolute left-0 right-0 top-full mt-1 flex flex-col gap-y-px overflow-hidden rounded-sm bg-white shadow-md shadow-gray-500/20 md:left-auto md:right-0 md:w-[300px]"
      >
        {children}
      </Menu.Items>
    </Menu>
  );
}

HeaderMenu.Item = Item;

export default function ApplicationShell({ children, title }: Props) {
  const router = useRouter();
  const { config, configs, setConfig } = useContext(ConfigContext);
  const authUser = useAuthUser();

  useSetConfig();

  if (!authUser?.data) {
    // TODO: show loading screen while fetching user data?!
    return null;
  }

  return (
    <div className="">
      <Head>
        <title>{title}</title>
      </Head>
      <div className="bg-white shadow-lg shadow-slate-200">
        <div className="container flex h-24 items-center justify-between">
          <Link href="/" className="text-4xl font-bold text-blue">
            <Logo className="-ml-3 h-3" />
            MOCO
          </Link>
          <nav className="h-full">
            <ul className="flex h-full">
              {navigation.map((item) => (
                <li
                  key={item.href}
                  className={`-mb-1 border-b-4 border-transparent ${
                    item.isActive.test(router.pathname)
                      ? "border-slate-300"
                      : ""
                  }`}
                >
                  <Link
                    href={item.href}
                    className={`relative flex h-full items-center space-x-3 border-2 border-transparent px-4 text-sm font-medium text-blue outline-none hover:bg-slate-50 focus-visible:border-blue-500 ${
                      item.isActive.test(router.pathname) ? "bg-slate-50" : ""
                    }`}
                  >
                    <item.icon className="h-6" aria-hidden="true" />
                    <span>{item.name}</span>
                  </Link>
                </li>
              ))}
              <Menu as="li" className="relative">
                {({ open, close }) => (
                  <>
                    <Menu.Button
                      className={`flex h-full items-center space-x-3 border-2 border-transparent p-4 text-sm font-medium text-blue outline-none hover:bg-slate-50 focus-visible:border-blue-500 ${
                        open ? "bg-slate-50" : ""
                      }`}
                    >
                      <UserCircleIcon className="h-6" />
                      <span>Account</span>
                      <ChevronDownIcon
                        className={`h-5 transition-transform ${
                          open ? "rotate-180" : ""
                        }`}
                      />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 z-20 mt-2 w-64 overflow-hidden rounded-lg rounded-b border border-slate-200 bg-white shadow-lg">
                      <div className="border-b border-slate-200 bg-slate-100 px-4 py-3 text-sm font-medium text-gray-500">
                        <div className="flex flex-col">
                          <div className="font-semibold text-blue-950">
                            {authUser.data.display_name}
                          </div>
                          <div className="font-medium text-cyan-800">
                            {config?.title}
                          </div>
                        </div>
                      </div>
                      {configs?.length
                        ? configs
                            .sort((a, b) => a.title.localeCompare(b.title))
                            .map((c) => (
                              <button
                                className={`flex w-full items-center space-x-4 py-2.5 pl-4 text-sm text-cyan-800  ${
                                  c.slug === config?.slug
                                    ? "pointer-events-none bg-lime-50 font-bold"
                                    : "font-medium hover:bg-slate-50"
                                }`}
                                key={c.slug}
                                onClick={async () => {
                                  localStorage.setItem("moco.config", c.slug);
                                  if (
                                    c?.settings?.extent_topographic !==
                                    config?.settings?.extent_topographic
                                  ) {
                                    window.localStorage.setItem(
                                      LS_MAP_EXTENT_KEY,
                                      JSON.stringify(
                                        getExtentFromString(
                                          c?.settings?.extent_topographic,
                                        ),
                                      ),
                                    );
                                  }
                                  updateConfig(c, setConfig);
                                  close();
                                  router.push("/");
                                }}
                              >
                                <div
                                  className={`h-2 w-2 rounded-full ${
                                    c.slug === config?.slug ? "bg-lime-500" : ""
                                  }`}
                                />
                                <div>{c.title}</div>
                              </button>
                            ))
                        : null}
                      <Menu.Item>
                        <a
                          href="#"
                          onClick={() => authUser.logout()}
                          className={`flex h-full items-center space-x-2 border-slate-200 py-2.5 pl-4 text-sm font-medium text-blue hover:bg-slate-50 ${
                            configs?.length ? "border-t" : ""
                          }`}
                        >
                          <ArrowRightOnRectangleIcon className="-ml-1 w-5" />
                          <span>Abmelden</span>
                        </a>
                      </Menu.Item>
                    </Menu.Items>
                  </>
                )}
              </Menu>
            </ul>
          </nav>
        </div>
      </div>
      {config ? (
        <main className="container mt-8">{children}</main>
      ) : (
        <div className="container mt-8 text-red-500">
          Keine Konfiguration gefunden.
        </div>
      )}
    </div>
  );
}
