const defaultConfigSettings = {
  stops_mots: "rail",
  routing_mot: "rail",
  stops_api_bbox: "4.768066,45.228481,17.600098,55.216490", // DACH
  graph_schematic: "",
  extent_schematic: "",
  graph_topographic: "osm",
  extent_topographic: "562576.5282,5704036.7988,1956787.9241,7396658.3531", // DACH
  stops_api_prefagencies: "db,sbb",
  style_schematic: "",
  style_topographic: "travic_v2_generalized",
  styles: "travic_v2_generalized",
  "moco-preview_url": "",
  "moco-preview_url_dev": "",
  "moco-preview_url_stag": "",
  "moco-default_affected_product_names": "",
};

export default defaultConfigSettings;
