const tilesUrl =
  process.env.NEXT_PUBLIC_TILES_URL || "https://maps.geops.io/styles";

const fetchStyleGraphs = (styleName) => {
  return fetch(
    `${tilesUrl}/${styleName}/style.json?key=${process.env.NEXT_PUBLIC_GEOPS_API_KEY}`,
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      const graphsObject = data?.metadata?.graphs || {};
      return {
        [styleName]: Object.keys(graphsObject).map((key) => graphsObject[key]),
      };
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
};

export default fetchStyleGraphs;
