export const LS_MAP_EXTENT_KEY = "moco3.notificationsMap.extent";

export default function getExtentFromString(string: string) {
  if (!string) {
    return null;
  }
  if (/\|/.test(string)) {
    return string.split("|").map((s) => parseFloat(s));
  }
  return string.split(",").map((s) => parseInt(s));
}
