import { useContext, useEffect } from "react";
import useAuthUser from "./useAuthUser";
import { ConfigContext } from "../pages/_app";
import defaultConfigSettings from "../lib/defaultConfig";
import updateConfig from "../lib/updateConfig";

const useConfigs = () => {
  const { setConfig, setConfigs } = useContext(ConfigContext);
  const authUser = useAuthUser();

  useEffect(() => {
    if (authUser?.data) {
      const configs = authUser.data.configs.map((conf) => ({
        ...conf,
        settings: Object.keys(conf.settings).reduce(
          (finalSettings, currentKey) => {
            return {
              ...finalSettings,
              [currentKey]: conf.settings[currentKey]
                ? conf.settings[currentKey]
                : defaultConfigSettings[currentKey],
            };
          },
          {},
        ),
      }));
      setConfigs(configs);

      const configSlug = global.localStorage?.getItem("moco.config");
      const config = configSlug
        ? configs.find((c) => c.slug === configSlug)
        : configs[0];
      updateConfig(config, setConfig);
    }
  }, [authUser?.data, setConfig, setConfigs]);
};

export default useConfigs;
