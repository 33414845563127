import fetchStyleGraphs from "./fetchStyleGraphs";

type Config = {
  settings: {
    styles: string;
    graphs?: object;
  };
};

const updateConfig = async (
  config: Config,
  callback: (config: Config) => Config,
) => {
  const styles = config.settings?.styles;
  const promises = (styles.split(",") || []).map((style) =>
    fetchStyleGraphs(style),
  );
  const graphs = await Promise.all(promises);
  callback({
    ...config,
    settings: {
      ...config.settings,
      // ['moco-preview_url_dev']: 'https://localhost:3001/',
      graphs: graphs.reduce<object>((final, obj) => ({ ...final, ...obj }), {}),
    },
  });
};

export default updateConfig;
