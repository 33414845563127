import { useRouter } from "next/router";
import useSWR from "swr";

export default function useAuthUser() {
  const router = useRouter();
  const result = useSWR("/auth/user/");

  if (result.error) {
    router.push("/login/");
    return;
  }

  const logout = async () => {
    window.location.href = "/backend/oidc/logout/";
  };

  return { ...result, logout };
}
